import React from "react"
import { connect, Field } from "formik"
import { Translate } from "react-redux-i18n"
import { Stack } from "@mui/material"

import {
  TextField,
  DatePicker,
  DateTimePicker,
  FilesDropzone,
  RemoteAutocomplete
} from "shared/ui/berry-jass-formik"
import { getAlternativeContactsOptionLabel } from "entities/contact"
import { ATTACHMENT_MIME_TYPES } from "../lib"

export const TaskForm = connect(
  ({
    showCustomer,
    setCustomer,
    handleDeleteAttachment,
    formik: { values, setFieldValue }
  }) => {
    return (
      <Stack spacing={3}>
        {showCustomer && (
          <Field
            fullWidth
            name="jap_customer_id"
            component={RemoteAutocomplete}
            paginated
            id="jap_customer_id"
            size="small"
            data-testid="customer-select"
            label={<Translate value="todo-center.filters.customer" />}
            mapResponseToDataSource={(response) => response?.data ?? []}
            onChange={(_e, value) => {
              setFieldValue("contact_id", null)
              setCustomer?.(value)
            }}
            url="/autocomplete/jap_customers"
            params={{ q: { type_in: ["EndCustomer", "PartnerCustomer"] } }}
            variant="ransack"
            searchProp="q.customer_number_or_company_name_cont"
            getOptionLabel={(customer) =>
              `${customer.customer_number} | ${customer.company_name}`
            }
          />
        )}
        <Field
          fullWidth
          name="contact_id"
          disabled={!values.jap_customer_id}
          component={RemoteAutocomplete}
          paginated
          id="contact_id"
          size="small"
          data-testid="contact-select"
          label={<Translate value="todo-center.filters.contact" />}
          mapResponseToDataSource={(response) => response?.data ?? []}
          url="/autocomplete/jap_customers/contacts"
          params={{ q: { by_customer_id: values.jap_customer_id?.id } }}
          variant="ransack"
          searchProp="q.first_name_or_last_name_or_phone_or_email_or_company_cont"
          getOptionLabel={getAlternativeContactsOptionLabel}
        />
        <Field
          fullWidth
          name="title"
          label={<Translate value="todo-dialog.fields.name" />}
          component={TextField}
        />
        <Field
          fullWidth
          name="description"
          label={<Translate value="todo-dialog.fields.description" />}
          component={TextField}
          multiline
          rows={5}
        />
        <Field
          name="attachments"
          multiple
          compactView
          showFilesList={false}
          component={FilesDropzone}
          onDrop={(acceptedFiles) => {
            setFieldValue("attachments", [
              ...values.attachments,
              ...acceptedFiles
            ])
          }}
          onDelete={handleDeleteAttachment}
          onDownload={(file) => window.open(file?.url, "_blank")}
          hint={<Translate value="activity-attachments.upload-hint" />}
          accept={ATTACHMENT_MIME_TYPES}
        />
        <Field
          id="due-date-datepicker"
          name="due_date"
          clearable
          disablePast
          component={DatePicker}
          label={<Translate value="todo-dialog.fields.due-date" />}
        />
        <Field
          id="reminder-time"
          name="reminder.time"
          clearable
          disablePast
          component={DateTimePicker}
          label={<Translate value="todo-dialog.fields.reminder-time" />}
        />
      </Stack>
    )
  }
)
