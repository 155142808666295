import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query"
import keys from "lodash/keys"
import client from "utils/client"
import { getNextPageParam } from "shared/lib/ransack"

export const useTodoTasks = (params, options = {}) => {
  return useInfiniteQuery({
    queryKey: ["v2", "activities", "tasks", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/v2/activities/tasks.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    getNextPageParam,
    ...options
  })
}

export const useUpdateTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, ...values }) => {
      const formData = new FormData()
      const paramKeys = keys(values)

      paramKeys.map((field) => {
        if (field === "attachments") {
          values.attachments
            .filter((attachment) => !attachment.id)
            .forEach((attachment) =>
              formData.append(
                "task[attachments_attributes][][file]",
                attachment
              )
            )
        } else if (field === "reminder") {
          const reminder = values[field]
          if (reminder?.id) {
            formData.append("task[reminder_attributes][id]", reminder.id)
          }

          if (reminder?.time) {
            formData.append("task[reminder_attributes][time]", reminder.time)
          }
        } else {
          formData.append(`task[${field}]`, values[field])
        }
      })

      const response = await client.put(
        `/v2/activities/tasks/${id}.json`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )

      return response.data
    },

    onSuccess: () => {
      queryClient.invalidateQueries(["v2", "activities", "tasks"])
      queryClient.invalidateQueries(["v2", "activities", "notes", "index"])
    }
  })
}

export const useTask = (id) => {
  return useQuery({
    queryKey: ["v2", "activities", "tasks", "show", id],
    queryFn: async () => {
      const response = await client.get(`/v2/activities/tasks/${id}.json`)

      return response.data
    },
    enabled: !!id
  })
}

export const useCreateTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values) => {
      const formData = new FormData()
      const paramKeys = keys(values)

      paramKeys.map((field) => {
        if (field === "attachments") {
          values.attachments.forEach((attachment) =>
            formData.append("task[attachments_attributes][][file]", attachment)
          )
        } else if (field === "reminder") {
          const reminder = values[field]

          if (reminder?.time) {
            formData.append("task[reminder_attributes][time]", reminder?.time)
          }
        } else {
          formData.append(`task[${field}]`, values[field])
        }
      })

      const response = await client.post(
        "/v2/activities/tasks.json",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )

      return response.data
    },

    onSuccess: () => {
      queryClient.invalidateQueries(["v2", "activities", "tasks", "index"])
    }
  })
}

export const useDeleteTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) => {
      const response = await client.delete(`/v2/activities/tasks/${id}.json`)

      return response.data
    },

    onSuccess: () => {
      queryClient.invalidateQueries(["v2", "activities", "tasks", "index"])
    }
  })
}
