import React from "react"
import { Translate } from "react-redux-i18n"
import { Box, Stack } from "@mui/material"
import AttachmentIcon from "@mui/icons-material/Attachment"
import NotificationsIcon from "@mui/icons-material/Notifications"
import moment from "moment"

import { COLUMN_WIDTH_AUTO } from "shared"
import { ExpandCell } from "shared/ui/berry-jass"
import { DoneCell } from "../ui"

export const completedColumn = {
  id: "completed",
  size: 45,
  cell: (info) => {
    const task = info.row.original
    return (
      <Box sx={{ ml: "-12px" }}>
        <DoneCell task={task} />
      </Box>
    )
  }
}

export const titleColumn = {
  accessorKey: "title",
  size: COLUMN_WIDTH_AUTO,
  cell: (info) => {
    const { title, due_date, done, contact_name, reminder, attachments } =
      info.row.original
    const iconSx = {
      fontSize: "14px !important",
      color: "primary.main",
      alignItems: "center"
    }
    const dueDate = moment(due_date)

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Box sx={{ fontSize: 14, fontWeight: 500 }}>{title}</Box>
        {contact_name && (
          <Box sx={{ fontSize: 12, fontWeight: 400 }}>{contact_name}</Box>
        )}
        <Box sx={{ fontSize: 12, fontWeight: 400 }}>
          <Stack spacing={1} direction="row">
            <Box
              sx={{
                color:
                  !done && dueDate.isBefore(moment(), "day")
                    ? "error.main"
                    : null
              }}
            >
              <Translate
                value="activities.task-due-date"
                date={dueDate.format("dddd, MMM D")}
              />
            </Box>
            {!!reminder && <NotificationsIcon sx={iconSx} />}
            {!!attachments?.length && <AttachmentIcon sx={iconSx} />}
          </Stack>
        </Box>
      </Box>
    )
  }
}

export const expandColumn = {
  id: "expand",
  size: 50,
  cell: (info) =>
    info.row.getCanExpand() ? <ExpandCell row={info.row} /> : null
}
